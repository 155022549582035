import { useEffect, useState } from "react";
import "../src/App.css";
import logo from "./logo.jpg";
import {
  AppBar,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeleteAccount = () => {
  const { id } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
  });

  useEffect(() => {
    setFormData({
      ...formData,
      userName: id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const baseUrl = "https://tsmiscwebapi.azurewebsites.net";

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    setOpenDialog(false);
    const obj = {
      Email: formData?.userName,
      Password: formData?.password,
      NotifyToken: "",
      AppName: "ThermalHub",
      AppVersion: "",
      MobileDeviceModel: "",
      Platform: "",
      PlatformVersion: "",
      IsDeviceNotify: "true",
      DeviceName: "iPhone",
    };

    try {
      const res = await axios.post(
        `${baseUrl}/api/UserInformation/CheckUser`,
        obj
      );
      if (res.status === 200) {
        handleDeleteAccount();
        setFormData(
          {
          userName: "",
          password: "",
          }
        )
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Incorrect Password Please Try Again");
      } else {
        console.error("An error occurred:", error);
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  const handleDeleteAccount = async () => {
    const obj = {
      UserId: formData?.userName,
    };

    const res = await axios.post(
      `${baseUrl}/api/UserInformation/DeactivateUserAccount`,
      obj
    );
    if (res.status === 201) {
      toast.success("Account Deleted Successfully");
    }
  };

  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = (e) => {
    e.preventDefault();
    setOpenDialog(true);
  };
  return (
    <>
      <AppBar
        position="static"
        style={{
          backgroundColor: "#fff",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Toolbar>
          <img src={logo} alt="ThermalHub Logo" className="logo" />
        </Toolbar>
      </AppBar>
      <div className="container">
        <div className="content">
          <Card className="card">
            <CardContent>
              <div className="Title">
                <Typography variant="h4" component="h1" className="title">
                  ThermalHub
                </Typography>
              </div>
              <Typography variant="h6" component="h2" className="sub-title">
                Account to be removed
              </Typography>
              <form className="form">
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <TextField
                    id="username"
                    className="input-field"
                    name="userName"
                    variant="outlined"
                    value={formData?.userName}
                    type="text"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <TextField
                    id="password"
                    className="input-field"
                    name="password"
                    variant="outlined"
                    value={formData?.password}
                    type={showPassword ? "text" : "password"}
                    onChange={handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <Button onClick={handleTogglePasswordVisibility}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </Button>
                      ),
                    }}
                  />
                </div>
                <div className="submit-button">
                  <Button
                    type="submit"
                    variant="contained"
                    color="error"
                    onClick={(e) => handleOpenDialog(e)}
                  >
                    Delete Account
                  </Button>
                </div>
                <Typography>
                  All your personal data ( Name, Email, Date Of Birth, Phone,
                  Address) will be deleted from database
                </Typography>
              </form>
            </CardContent>
          </Card>
        </div>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete your account?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleLogin();
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default DeleteAccount;
