import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DeleteAccount from './DeleteAccount';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div>
       <ToastContainer position="top-center"/>
      <BrowserRouter>
      <Routes>
        <Route path="/:id?" element={<DeleteAccount />}></Route>
        </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
